import React, {useEffect, useState, useRef, FC} from 'react';
import ReactTooltip from 'react-tooltip';
import {Link, navigate} from 'gatsby';
import classNames from 'classnames';
import {Location} from '@reach/router';
import '../../styles/responsive.scss';
import '../../styles/bootstrap.min.css';
import logo from '../../images/hexowatch-logo-white.svg';
import {HeaderState, MenuItem, HeaderProps} from '../../types';
import './styles.scss';
import {Translate} from '../translations';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../styles/responsive.scss';
import '../../styles/bootstrap.min.css';
//import CookiePolicy from "../../components/cookie-policy";
import './styles.scss';
import {faSignOutAlt, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {
  getUserEmail,
  getUserName,
  logOut,
  // setHidePromoHeader,
  // getHidePromoHeader,
  // setPremiumAnnualModal,
  // removeHidePromoHeader,
  // setPromoHeader,
  // removePromoHeader,
  // getUserToken,
  getParsedDateAndTimeGMT,
  getRawCookie,
  getLTDPromo329,
  getPromoDate,
} from '../../helpers';
import HeaderMenuItem from './item';
import HeaderMenuSubItem from './sub-item';
import Counter from './counter';
// import { closeIcon } from '../../images/svgIcons';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ADD_REFERRAL, LOG_OUT} from '../../graphql/mutations';
// import holidaysSale from '../../images/pricing/holidays-sale.svg';
// import holidaysSaleMobile from '../../images/pricing/flash-promo/flash-promo.svg';
// import background1 from '../../images/pricing/flash-promo/background1.svg';
// import background2 from '../../images/pricing/flash-promo/background2.svg';

import backgroun1 from '../../images/hexomatic/background-1.svg';
import backgroun2 from '../../images/hexomatic/background-2.svg';
import promoText from '../../images/hexomatic/promo-text.svg';
import mobileBanner from '../../images/hexomatic/banner-mobile.svg';

import backgroun1Promo from '../../images/limited-time-ltd/background1.svg';
import backgroun2Promo from '../../images/limited-time-ltd/background2.svg';
import promoTextPromo from '../../images/limited-time-ltd/promo-text-no-user.svg';
import mobileBannerPromo from '../../images/limited-time-ltd/mobile-banner-no-user.svg';

import backgroun1PromoAnnual from '../../images/annual-discount/background-1.svg';
import backgroun2PromoAnnual from '../../images/annual-discount/background-2.svg';
import promoTextPromoAnnual from '../../images/annual-discount/promo-text.svg';
import mobileBannerPromoAnnual from '../../images/annual-discount/mobile-banner.svg';

// import promoTextNoUser from '../../images/limited-time-ltd/promo-text-no-user.svg';
// import mobileBannerNoUser from '../../images/limited-time-ltd/mobile-banner-no-user.svg';
import {GET_USER_WATCH_SETTINGS_QUERY, HEXOFY_USER, USER_PLANS_QUERY} from '../../graphql/queries';
import {useMediaQuery} from 'react-responsive';
import {openCalendly} from '../../helpers/trackingEvents';

const generateMenu = (items: Array<MenuItem>, pathName: string, gravatarSrc?: string): Array<JSX.Element> => {
  return items.map(
    (item: MenuItem): JSX.Element => {
      return (
        <li
          key={item.name}
          className={classNames('nav-item dropdown submenu', {
            active: item.linkTo === pathName || item.showSubItems,
            dropdownMenu: item.subMenu,
          })}
        >
          <HeaderMenuItem
            to={item.linkTo}
            name={item.name}
            target={item.target}
            icon={item.icon}
            img={item.img}
            src={gravatarSrc}
            className={item.className}
            func={item.func}
            showSubItems={item.showSubItems}
          />
          {item.subItems && (
            <ul
              className={classNames('dropdown-menu', {
                show: item.showSubItems,
              })}
            >
              {item.subItems.map(
                (subItem: MenuItem): JSX.Element => (
                  <HeaderMenuSubItem
                    key={subItem.name}
                    to={subItem.linkTo}
                    name={subItem.name}
                    target={subItem.target}
                    icon={subItem.icon}
                    className={subItem.className}
                    func={subItem.func}
                  />
                ),
              )}
            </ul>
          )}
          {item.subMenu && (
            <ul
              className={classNames('subMenu', {
                show: item.showSubItems,
              })}
            >
              {item.subMenu.map(
                (subItem: MenuItem): JSX.Element => (
                  <HeaderMenuSubItem
                    key={subItem.name}
                    to={subItem.linkTo}
                    name={subItem.name}
                    target={subItem.target}
                    icon={subItem.icon}
                    className={subItem.className}
                    func={subItem.func}
                  />
                ),
              )}
            </ul>
          )}
        </li>
      );
    },
  );
};

const Header: FC<HeaderProps> = ({menuItems, user, loading, refetchUser, promoPage, noBg, specialLtd}) => {
  const [state, changeState] = useState<HeaderState>({
    isMenuCollapsed: true,
    navBarFixClassName: 'header_area',
    isButtonClicked: false,
    isDropDownOpened: false,
  });
  // const [showInvestPopup, setShowInverstPopup] = useState(false);
  // const [validDate, setValidDate] = useState(false);
  const {data: hexowatchUserInfo, loading: hexowatchLoading} = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  // const {data: allPlansData, loading: allPlansLoading} = useQuery(USER_PLANS_QUERY, {
  //   fetchPolicy: 'no-cache',
  // });

  // const {data: hexofyUserData} = useQuery(HEXOFY_USER, {
  //   fetchPolicy: 'no-cache',
  // });
  // const {data: flashPromo, loading: flashPromoLoading} = useQuery(GET_WATCH_FLASH_PROMO_DATA);

  // const [holidaysPromo, setHolidaysPromo] = useState(false);
  const refUserName = useRef<HTMLParagraphElement | null>(null);
  const refName = useRef<HTMLParagraphElement | null>(null);
  const [checkWidth, setCheckWidth] = useState({firstName: false, firstName1: false});
  const [isGravatarOpen, setIsGravatarOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [annualPromo, setAnnualPromo] = useState(false);
  const [showHexomaticBanner, setShowHexomaticBanner] = useState(false);

  // const [flashPromoDate, setFlashPromoDate] = useState(null as any);

  const [promoOpen, setPromoOpen] = useState(false);
  // const [currentPackage, setCurrentPackage] = useState('');
  // const [currentPackageInterval, setCurrentPackageInterval] = useState('');

  const [email, setEmail] = useState('email');
  // const [promoClosed, setPromoClosed] = useState(false);

  const [addRefferal] = useMutation(ADD_REFERRAL);
  const [log_out] = useMutation(LOG_OUT);
  // const handlePromoClose = () => {
  //   const el = document.getElementById("promo-header");
  //   //@ts-ignore
  //   el && el.animate({ opacity: "0" }, 150);
  //   setTimeout(() => {
  //     setPromoClosed(true);
  //     setHidePromoHeader();
  //     const el = document.getElementsByClassName("padding-promo");
  //     for (let i = 0; i < el.length; i++) {
  //       el[i] && el[i].classList && el[i].classList.remove("padding-promo");
  //     }
  //   }, 150);
  // };

  const handleScrollEvent = () => {
    const top: number = document.documentElement.scrollTop || document.body.scrollTop;
    //116 instead of 1 for promos
    if (top > 60 && state.navBarFixClassName !== 'header_area navbar_fixed') {
      changeState({
        ...state,
        navBarFixClassName: 'header_area navbar_fixed',
      });
    } else if (top <= 60 && state.navBarFixClassName === 'header_area navbar_fixed') {
      changeState({
        ...state,
        navBarFixClassName: 'header_area',
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [state.navBarFixClassName]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let referral = urlParams.get('referralCode');
    if (referral) {
      if (referral[referral.length - 1] === '/') {
        referral = referral.slice(0, -1);
      }
      const d = new Date();
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
      window.document.cookie = `referralCode=${referral};expires=${d.toUTCString()};path=/`;
      // ADD_REFERRAL
      addRefferal({variables: {referralCode: referral}});
    }
  }, []);

  const toggle = () => {
    setIsGravatarOpen(!isGravatarOpen);
  };

  // const [color, dispatch] = useContext(ColorContext);

  const toggleHandler = (): void =>
    changeState({
      ...state,
      isMenuCollapsed: !state.isMenuCollapsed,
      isDropDownOpened: !state.isDropDownOpened,
    });

  const logOutHandler = async () => {
    const {data} = await log_out({variables: {id: user && +user.id, device_key: getRawCookie('device_key')}});
    if (data && data.UserOps && data.UserOps.logout && !data.UserOps.logout.error) {
      logOut();
      setEmail(getUserEmail() || '');
      refetchUser();
    }
  };

  // const logOutHandler = () => {
  //   logOut();
  //   setEmail(getUserEmail() || '');
  // };

  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const width = global.window && global.window.screen && global.window.screen.width;
  //   if (width < 1200) {
  //     setIsMobile(true);
  //   }
  // }, []);

  const isMobile = useMediaQuery({maxWidth: 1500});

  useEffect(() => {
    if (user?.firstName && refUserName.current && refName.current) {
      setCheckWidth({
        firstName: refUserName?.current?.scrollWidth !== refUserName?.current?.offsetWidth,
        firstName1: refName?.current?.scrollWidth !== refName?.current?.offsetWidth,
      });
    }
  }, [user, refUserName.current, refName.current]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const show = localStorage.getItem('become-investor-2');
  //     !show ? setShowInverstPopup(true) : setShowInverstPopup(false);
  //   }, 5000);
  // }, []);

  // const isMobileSm = useMediaQuery({maxWidth: 991});

  // useEffect(() => {
  //   const packageHexowatch =
  //     hexowatchUserInfo &&
  //     hexowatchUserInfo.UserWatchSettings &&
  //     hexowatchUserInfo.UserWatchSettings.get &&
  //     hexowatchUserInfo.UserWatchSettings.get.pricing_package;
  //   const packageHexowatchInterval =
  //     hexowatchUserInfo &&
  //     hexowatchUserInfo.UserWatchSettings &&
  //     hexowatchUserInfo.UserWatchSettings.get &&
  //     hexowatchUserInfo.UserWatchSettings.get.pricing_package_interval;
  //   if (packageHexowatch) {
  //     setCurrentPackage(packageHexowatch);
  //     setCurrentPackageInterval(packageHexowatchInterval);
  //   } else {
  //     setCurrentPackage('');
  //     setCurrentPackageInterval('');
  //   }
  // }, [hexowatchUserInfo]);

  // useEffect(() => {
  //   if (allPlansData?.User?.getUserPlans) {
  //     const userPlans = allPlansData?.User?.getUserPlans;
  //     const hexomaticPlanName = userPlans.hexomatic?.pricing_package;
  //     const hexowatchPlanName = userPlans.hexowatch?.pricing_package;
  //     const hexometerPlanName = userPlans.hexometer?.pricing_package;
  //     const hexosparkPlanName = userPlans.hexospark?.pricing_package;
  //     const hexomaticPlanInterval = userPlans.hexomatic?.pricing_package_interval;
  //     const hexowatchPlanInterval = userPlans.hexowatch?.pricing_package_interval;
  //     const hexometerPlanInterval = userPlans.hexometer?.pricing_package_interval;
  //     const hexosparkPlanInterval = userPlans.hexospark?.pricing_package_interval;

  //     const hexofyPlanInterval = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package_interval;
  //     const hexofyPlanName = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package;

  //     const hexomaticFree = hexomaticPlanName === 'FREE' || (userPlans && !hexomaticPlanName);
  //     const hexowatchFree = hexowatchPlanName === 'FREE' || (userPlans && !hexowatchPlanName);
  //     const hexometerFree = hexometerPlanName === 'FREE' || (userPlans && !hexometerPlanName);
  //     const hexosparkFree = hexosparkPlanName === 'FREE' || (userPlans && !hexosparkPlanName);
  //     const hexofyFree = hexofyPlanName === 'FREE' || (userPlans && !hexofyPlanName);
  //     const showModal =
  //       (hexomaticFree || hexomaticPlanInterval === 'LTD') &&
  //       (hexowatchFree || hexowatchPlanInterval === 'LTD') &&
  //       (hexometerFree || hexometerPlanInterval === 'LTD') &&
  //       (hexofyFree || hexofyPlanInterval === 'LTD') &&
  //       (hexosparkFree || hexosparkPlanInterval === 'LTD');
  //     if (hexofyUserData?.HexofyUser?.getHexofyUser && showModal) {
  //       setAnnualPromo(true);
  //     } else {
  //       setAnnualPromo(false);
  //     }
  //   }
  // }, [hexofyUserData, allPlansData]);

  useEffect(() => {
    const packageHexowatch =
      hexowatchUserInfo &&
      hexowatchUserInfo.UserWatchSettings &&
      hexowatchUserInfo.UserWatchSettings.get &&
      hexowatchUserInfo.UserWatchSettings.get.pricing_package;

    if (hexowatchUserInfo && !hexowatchLoading && (packageHexowatch === 'FREE' || !packageHexowatch)) {
      setAnnualPromo(false);
      setShowHexomaticBanner(true);
    } else if (!hexowatchLoading && packageHexowatch) {
      setAnnualPromo(false);
      setShowHexomaticBanner(true);
    }
  }, [hexowatchUserInfo, hexowatchLoading]);

  useEffect(() => {
    const el1 = document.getElementById('seo_home_area');
    if (true) {
      if (el1 && el1.classList && el1.classList.contains('noPaddingBottom')) {
        el1 && el1.classList.add('padding-promo');
      } else {
        el1 && el1.classList.add('padding-promo');
      }
    } else {
      // el1 && el1.classList.remove('padding-promo');
    }
  }, [promoOpen]);

  // useEffect(() => {
  // const showPromo =
  //   hexowatchUserInfo &&
  //   hexowatchUserInfo.User &&
  //   hexowatchUserInfo.User.get &&
  //   hexowatchUserInfo.User.get.promoData &&
  //   hexowatchUserInfo.User.get.promoData.hexowatch_ltd;
  // const userName =
  //   hexowatchUserInfo && hexowatchUserInfo.User && hexowatchUserInfo.User.get && hexowatchUserInfo.User.get.firstName;
  // if (showPromo && !promoPage) {
  //   setPromoOpen(true);
  //   userName && setFirstName(userName);
  // } else {
  //   setPromoOpen(false);
  // }
  // }, [hexowatchUserInfo, promoPage]);

  // useEffect(() => {
  //   const difference = +new Date('Apr 01 2023 16:00:00 GMT') - +new Date(new Date().toUTCString());
  //   if (difference > 0) {
  //     setValidDate(true);
  //   } else {
  //     setValidDate(false);
  //   }
  // }, []);

  return (
    <header className={`header_bg ${state.navBarFixClassName} ${noBg ? '' : 'with-bg'}`} data-testid="header">
      {!promoPage && annualPromo ? (
        <>
          <div
            className="w-100 position-relative d-none d-lg-flex"
            style={{background: '#C9F0FF', cursor: 'pointer', height: '60px'}}
            onClick={() => navigate('/pricing/')}
            // onClick={() =>
            //   (window.location.href =
            //     'https://hexomatic.com/EVERYTHING-SB-2024/?utm_source=hexowatch&utm_medium=hellobar')
            // }
          >
            <img src={backgroun1PromoAnnual} alt="" className="illustration1" />
            <div
              className="w-100 d-flex m-auto"
              style={{
                zIndex: 100,
                justifyContent: 'center',
                height: '100%',
                position: 'relative',
                maxWidth: '1000px',
                maxHeight: '60px',
              }}
            >
              <img
                src={promoTextPromoAnnual}
                alt=""
                className="my-auto promo-text"
                style={{maxWidth: '100%', maxHeight: '60px'}}
              />
              <Counter date={getPromoDate()} title="DEAL ENDS IN..." titleColor="#170045" />
            </div>
            <img src={backgroun2PromoAnnual} alt="" className="illustration2" />
          </div>
          <div
            className="w-100 position-relative d-flex d-lg-none"
            style={{background: '#C9F0FF', cursor: 'pointer'}}
            onClick={() => navigate('/pricing/')}
            // onClick={() =>
            //   (window.location.href =
            //     'https://hexomatic.com/EVERYTHING-SB-2024/?utm_source=hexowatch&utm_medium=hellobar')
            // }
          >
            <img
              src={mobileBannerPromoAnnual}
              alt=""
              className="m-auto"
              style={{maxHeight: '180px', height: 'auto', maxWidth: '100%'}}
              width={320}
              height={180}
            />
            <div className="m-auto counter_absolute">
              <Counter date={getPromoDate()} title="DEAL ENDS IN..." titleColor="#170045" />
            </div>
          </div>{' '}
        </>
      ) : promoPage ? (
        <>
          <div
            className="w-100 position-relative d-none d-lg-flex ltd-wrapper"
            style={{background: '#002138', cursor: 'pointer', height: '60px'}}
            onClick={() => navigate(specialLtd ? '/promo87282/#pricing' : '/promo14035/#pricing')}
          >
            <img src={backgroun1Promo} alt="" className="illustration1 d-none d-xl-flex" />
            <div
              className="w-100 d-flex m-auto"
              style={{
                zIndex: 100,
                justifyContent: 'center',
                height: '100%',
                position: 'relative',
                maxWidth: '1100px',
              }}
            >
              <img
                src={promoTextPromo}
                alt=""
                className="my-auto promo-text"
                style={{maxWidth: '100%', maxHeight: '62px'}}
              />
              <Counter date={getPromoDate()} title="DEAL ENDS IN..." titleColor="#fff" light />
            </div>
            <img src={backgroun2Promo} alt="" className="illustration2 d-none d-xl-flex" />
          </div>
          <div
            className="w-100 position-relative d-flex d-lg-none ltd-wrapper"
            style={{background: '#002138', cursor: 'pointer'}}
            onClick={() => navigate(specialLtd ? '/promo87282/#pricing' : '/promo14035/#pricing')}
          >
            <img
              src={mobileBannerPromo}
              alt=""
              className="m-auto"
              style={{maxHeight: '180px', height: 'auto', maxWidth: '100%'}}
              width={320}
              height={180}
            />
            <div className="m-auto counter_absolute">
              <Counter date={getPromoDate()} title="DEAL ENDS IN..." titleColor="#fff" light />
            </div>
          </div>{' '}
        </>
      ) : (
        !promoPage && showHexomaticBanner && (
          <>
            <div
              className="w-100 position-relative d-none d-lg-flex"
              style={{background: '#a500fb', cursor: 'pointer'}}
              onClick={() => (window.location.href = 'https://hexomatic.com/?utm_source=hexowatch&utm_medium=hellobar')}
            >
              <img src={backgroun1} alt="" className="illustration1 d-none d-xl-flex" />
              <div
                className="w-100 d-flex m-auto"
                style={{
                  zIndex: 100,
                  justifyContent: 'center',
                  height: '100%',
                  position: 'relative',
                  maxWidth: '1000px',
                }}
              >
                <img
                  src={promoText}
                  alt=""
                  className="my-auto promo-text"
                  style={{maxWidth: '100%', maxHeight: '62px'}}
                />
                {/* <Counter date={'Dec 01 2023 07:00:00 GMT'} title="DEAL ENDS IN..." titleColor="#fff" /> */}
              </div>
              <img src={backgroun2} alt="" className="illustration2 d-none d-xl-flex" />
            </div>
            <div
              className="w-100 position-relative d-flex d-lg-none"
              style={{background: '#a500fb', cursor: 'pointer'}}
              onClick={() => (window.location.href = 'https://hexomatic.com/?utm_source=hexowatch&utm_medium=hellobar')}
            >
              <img
                src={mobileBanner}
                alt=""
                className="m-auto"
                style={{maxHeight: '180px', height: 'auto', maxWidth: '100%', width: 'auto'}}
                width={320}
                height={180}
              />
              {/* <div className="m-auto counter_absolute">
              <Counter date={'Dec 01 2023 07:00:00 GMT'} title="DEAL ENDS IN..." titleColor="#fff" />
            </div> */}
            </div>{' '}
          </>
        )
      )}
      {/* {holidaysPromo && (
        <div
          className={`text-center d-flex flex-wrap justify-content-center holidays-sale`}
          id="promo-header"
          onClick={() => (window.location.pathname = '/pricing')}
        >
          <div className="d-flex justify-content-center" style={{width: '100%'}}>
            <img src={background1} alt="illustration1" className="illustration1 d-none d-xl-block" />
            <div className="d-lg-flex d-lg-flex-wrap justify-content-center">
              <img
                src={holidaysSaleMobile}
                style={{
                  maxWidth: '100%',
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '70px',
                  cursor: 'pointer',
                  padding: '10px 15px',
                }}
              />
              <div className="m-auto">
                <Counter date={getParsedDateAndTimeGMT(flashPromoDate)} />
              </div>
            </div>
            <img src={background2} alt="illustration2" className="illustration2 d-none d-xl-block" />
          </div>
        </div>
      )} */}
      {/* {promoOpen && (
        <div
          className={`text-center d-flex flex-wrap justify-content-center holidays-sale cursor-pointer`}
          // id="promo-header"
          onClick={() => navigate('/pricing_promo')}
        >
          <div className="justify-content-center d-none d-md-flex" style={{width: '100%'}}>
            <img src={background1BackToSchool} alt="illustration1" className="illustration1" />
            <div className="d-md-flex d-md-flex-wrap justify-content-center w-100">
              <img src={addOn2} className='d-none-xl-part m-auto' style={{maxWidth: '200px'}} />
              <img
                src={promoText}
                alt=""
                className="py-0 px-2"
                style={{maxWidth: '100%', margin: 'auto', zIndex: 1000, maxHeight: '69px'}}
              />
             
              <div className="mx-auto mt-4">
                <Counter date='Dec 26 2022 07:00:00 GMT' />
              </div>
            </div>
            <img src={background2BackToSchool} alt="illustration2" className="illustration2 d-none d-xl-block" />
          </div>
          <div className="d-block d-md-none justify-content-center" style={{width: '100%'}}>
            <img src={ltdMobile} alt="ltd" style={{maxHeight: '150px'}} />
            <div className="m-auto counter_absolute">
              <Counter date='Dec 26 2022 07:00:00 GMT' />
            </div>
          </div>
        </div>
      )} */}
      {/* {!getHidePromoHeader() && !promoClosed && (
        <div
          className={`p-2 text-center d-flex flex-wrap justify-content-center`}
          id="promo-header"
          style={{
            background: "linear-gradient(90deg, #03b8ff 0%, #0a85f7 100%)"
          }}
        >
          <div
            className="d-flex flex-wrap justify-content-center"
            style={{ width: "calc(100% - 30px)" }}
          >
            <div className="d-flex mr-2">
              <span className="promo-text">
                Halloween Special -
                <Link to="/pricing" className="hexowatch-external-link">
                  {" "}
                  Save 20%
                </Link>{" "}
                <span
                  className="hexowatch-external-link"
                  onClick={() => {
                    setPremiumAnnualModal();
                    window.location.href =
                      "https://dash.hexowatch.com/hexowatch";
                  }}
                ></span>
                all our annual plans
              </span>
            </div>
            <Counter />
          </div>
          <span className="close-promo" onClick={handlePromoClose}>
            {closeIcon}
          </span>
        </div>
      )} */}
      {/* <CookiePolicy
        title="COOKIE_POLICY_TITLE"
        subTitle="COOKIE_POLICY_SUBTITLE"
        actionText="COOKIE_POLICY_BUTTON_TEXT"
      /> */}
      <nav className="navbar navbar-expand-xl menu_one menu_five">
        <div className="container">
          <Link to="/" className="navbar-brand sticky_logo">
            <img src={logo} alt="Hexowatch.com" width={210} height={50} />
          </Link>
          <button
            className={classNames('navbar-toggler', {
              collapsed: state.isMenuCollapsed,
            })}
            onClick={toggleHandler}
            type="button"
            data-testid="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu_toggle">
              <span className="hamburger">
                <span />
                <span />
                <span />
              </span>
              <span className="hamburger-cross">
                <span />
                <span />
              </span>
            </span>
          </button>

          <div
            className={classNames('collapse navbar-collapse', {
              show: !state.isMenuCollapsed,
            })}
            id="navbarSupportedContent"
            data-testid="navbar-show"
          >
            <Location>
              {({location}) => (
                <ul className="navbar-nav menu w_menu pl_50">
                  {generateMenu(
                    menuItems,
                    location.pathname,
                    user?.photo || 'https://hexowatch.com/avatar-hexowatch.svg',
                  )}
                </ul>
              )}
            </Location>
          </div>

          {!state.isDropDownOpened && (
            <>
              {user && user.email && email ? (
                <div className={classNames('btn-group', {show: isGravatarOpen})}>
                  <button
                    type="button"
                    onClick={toggle}
                    className="logged-btn is-dropdown"
                    style={{
                      border: '0',
                      background: 'transparent',
                      color: '#fff',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span
                      ref={refUserName}
                      className="user-name-2 text-truncate text-center"
                      data-tip={checkWidth.firstName ? user?.firstName : ''}
                    >
                      {getUserName() || (user && user.firstName)}
                    </span>
                    <img
                      alt={getUserName() || (user && user.firstName)}
                      src={user?.photo || 'https://hexowatch.com/avatar-hexowatch.svg'}
                      style={{borderRadius: '50%'}}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      (window.location.href = window.location.host.includes('37.186.119.181')
                        ? 'http://37.186.119.181:3276'
                        : 'https://dash.hexowatch.com/profile')
                    }
                    className="logged-btn not-dropdown"
                    style={{
                      border: '0',
                      background: 'transparent',
                      color: '#fff',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <ReactTooltip place="bottom" className="tooltip" />
                    <span
                      ref={refName}
                      className="user-name text-truncate text-center"
                      data-tip={checkWidth.firstName1 ? user?.firstName : ''}
                    >
                      {getUserName() || (user && user.firstName)}
                    </span>

                    <img
                      alt={getUserName() || (user && user.firstName)}
                      src={user?.photo || 'https://hexowatch.com/avatar-hexowatch.svg'}
                      style={{borderRadius: '50%'}}
                    />
                  </button>
                  {isGravatarOpen && <button className="dropdown-back" type="button" onClick={toggle} />}
                  <div className="is-dropdown">
                    <div
                      className={classNames('dropdown-menu dropdown-menu-right', {
                        show: isGravatarOpen,
                      })}
                    >
                      <li>
                        <a
                          href={
                            global &&
                            global.window &&
                            global.window.location &&
                            global.window.location.host &&
                            global.window.location.host.includes('37.186.119.181')
                              ? 'http://37.186.119.181:3276'
                              : 'https://dash.hexowatch.com'
                          }
                          className="dropdown-item m-0 p-0"
                          no-index
                        >
                          <FontAwesomeIcon icon={faChartLine} className="user-icon" />
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item m-0 p-0" onClick={logOutHandler}>
                          <FontAwesomeIcon icon={faSignOutAlt} className="user-icon" />
                          Sign out
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="not-dropdown">
                    <div style={{margin: '0 10px'}}>
                      <a
                        href={
                          global.window.location.host.includes('37.186.119.181')
                            ? 'http://37.186.119.181:3276'
                            : 'https://dash.hexowatch.com'
                        }
                        className="dropdown-item p-0"
                        no-index
                      >
                        <FontAwesomeIcon icon={faChartLine} className="user-icon" />
                        Dashboard
                      </a>
                    </div>
                    <div style={{marginLeft: '10px'}}>
                      <a className="dropdown-item p-0" onClick={logOutHandler}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="user-icon" />
                        Sign out
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="pulse-placeholder">
                      <div className="line-placeholder"></div>
                      <div className="line-placeholder-long"></div>
                      <div className="line-placeholder-last"></div>
                    </div>
                  ) : (
                    <>
                      <Link
                        className="software_banner_btn d-none d-md-block header_registration_btn"
                        to="/registration"
                        state={{
                          goBack: true,
                          navigatePath:
                            global?.window?.location?.pathname?.includes('pricing') ||
                            global?.window?.location?.pathname?.includes('promo') ||
                            global?.window?.location?.pathname?.includes('5-year-journey')
                              ? global.window.location.pathname
                              : '',
                        }}
                      >
                        Get started
                      </Link>
                      <Link
                        className="d-none d-md-block header_login_btn"
                        to="/login"
                        state={{
                          goBack: true,
                          navigatePath:
                            global?.window?.location?.pathname?.includes('pricing') ||
                            global?.window?.location?.pathname?.includes('promo') ||
                            global?.window?.location?.pathname?.includes('5-year-journey')
                              ? global.window.location.pathname
                              : '',
                        }}
                      >
                        <Translate name="SIGN_IN" />
                      </Link>
                      {/* <div
                        className="software_banner_btn d-none d-md-block header_registration_btn ml-3"
                        onClick={openCalendly}
                      >
                        Request a demo
                      </div> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </nav>
      {/* {showInvestPopup && validDate ? (
        <Modal
          isOpen={showInvestPopup && validDate}
          modalToggle={() => {}}
          modalFooter={<></>}
          className="banner-modal"
        >
          <span
            className="position-absolute close-icon"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowInverstPopup(false);
              localStorage.setItem('become-investor-2', 'true');
            }}
          >
            {closeIcon}
          </span>
          <img
            src={isMobileSm ? popupMobile : popupDesktop}
            alt="Become an investor in Hexact"
            onClick={() => window.open('https://hexact.io/startengine/?utm_source=hexowatch&utm_medium=pop-up', '_blank')}
          />
          <div className="popup-counter">
            <Counter date="Apr 01 2023 16:00:00 GMT" className="popup-countdown" />
          </div>
        </Modal>
      ) : null} */}
    </header>
  );
};

export default Header;
